import React, { useState } from "react";

import { resetCookieConsentValue } from "react-cookie-consent";

import Layout from "../containers";
import { SEO, Container } from "../components/elements";
import useFirebaseAnalytics from "../hooks/useFirebaseAnalytics";

const SettingsPage = ({ location }) => {
  const [state, setState] = useState({});
  const title = "Settings";

  useFirebaseAnalytics(`PAGE: Settings ${location.pathname}`);
  return (
    <Layout>
      <Container>
        <SEO title={title} />
        <h1>Settings</h1>
        <h2>Cookies</h2>
        <button
          type="button"
          onClick={() => {
            resetCookieConsentValue("coffeeBooksAndCake");
            setState({ cookies: "Cookies Reset" });
          }}
        >
          Reset/Clear Cookies
        </button>
        {state.cookies ? <p>{state.cookies}</p> : null}
      </Container>
    </Layout>
  );
};

export default SettingsPage;
